import React from "react";

import "./TeamCard.css";

import SocialIcon from "../SocialIcon/SocialIcon";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import SocialIconsContainer from "../SocialIcon/SocialIconsContainer";

function TeamCard({ name, img, linkedin, mail, position }) {
    const im1 = require("../../assets/img/team/" + img);
    return (
        <div className={"team-card-box"}>
            <div className={"team-card"}>
                <img className={"team-avatar"} src={im1} alt="" />
                <p className={"team-name"}>{name}</p>
                <p className={"team-position"}>{position}</p>
                <SocialIconsContainer>
                    <SocialIcon icon={<FaLinkedinIn />} link={linkedin} />
                    <SocialIcon
                        icon={<AiOutlineMail />}
                        link={"mailto:" + mail}
                    />
                </SocialIconsContainer>
            </div>
        </div>
    );
}

export default TeamCard;
