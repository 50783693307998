import React from "react";
import HeadingWParagraph from "../../Components/HeadingWParagraph/HeadingWParagraph";

function AboutCarouselCard({ image, text, heading }) {
    return (
        <div className={"about-carousel flex-row"}>
            <img src={image} className={"about-img"} alt="" />
            <HeadingWParagraph heading={heading} paragraph={text} />
        </div>
    );
}

export default AboutCarouselCard;
