import React from "react";
import "./ScheduleCard.css";
import { TbPodium, TbTrophy, TbManualGearbox } from 'react-icons/tb';

export default function ScheduleCard(props) {
    return (
        <div className="scheduleCardOuter">
            <div className="scheduleCardIconCont">
                <div className="scheduleCardIcon">{
                    props.eventType === "competition" ? <TbTrophy /> : props.eventType === "workshop" ? <TbManualGearbox /> : <TbPodium />
                }
                </div>
                <div className="scheduleCardConnector"></div>
            </div>
            <div className="scheduleCardText">
                <h3>{props.eventName}</h3>
                <div>{props.eventTime + " | " + props.eventVenue}</div>
            </div>
        </div>
    );
}
