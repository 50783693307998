import React from "react";
import "./TeamNameCard.css";

function TeamNameCard({src, title, id, changeTeam, ...props }) {
    return (
        <div className="teamNameCardDiv">
            <img src={src} alt="TeamNameCard" className="teamNameCardPic" />
            <a href="#teamPage-carousel">
            <div className="teamNameCardTitle" onClick={() => changeTeam(id)}>
                {title}
            </div>
            </a>
        </div>
    );
}

export default TeamNameCard;
