import React from "react";
import image1 from "../../../../assets/img/blogs/blackberry/image1.jpg";
import image2 from "../../../../assets/img/blogs/blackberry/image2.png";
import image3 from "../../../../assets/img/blogs/blackberry/image3.jpg";

import "../style.css";
import LabeledImage from "../../../../Components/LabeledImage/LabeledImage";

function Content(props) {
    return (
        <div className={"blog-content-sections"}>
            <div className="content-section">
                <p>
                    Blackberry launched its first handheld device in the year
                    1999 in the form of a two-way pager. Who would have thought
                    back then that the company with an innovative product like
                    this will soon see it’s fall?
                </p>
            </div>
            <div className="content-section">
                <p>
                    If we go back in time, Nokia and Blackberry were the most
                    popular cellular mobile brands in the 2000s. In the 2000s,
                    Blackberry released mobile phones such as Curve Bold, Pearl
                    and others that became widely popular among both corporate
                    consumers as well as normal users. It was not just the
                    QWERTY keypad that stood out of Blackberry, but applications
                    such as Blackberry Messenger (BBM) were extremely popular,
                    even among the youngsters. Blackberry vs
                </p>
            </div>
            <div className="content-section column">
                <LabeledImage
                    image={image1}
                    label={"Blackberry vs IPhone Annual Revenue"}
                />
            </div>
            <div className="content-section">
                <p>
                    The release of the iPhone 4 in 2010 was the beginning of the
                    end for Blackberry as it became the most popular smartphone
                    in the US. Although, Blackberry had not considered iPhones
                    and Android-based phones as a threat because of its
                    offerings primarily focusing on corporates and business
                    professionals. However, the users started to feel what the
                    Blackberry lacked and they started to make switches to the
                    iPhones or the early Android phones.
                </p>
            </div>
            <div className="content-section">
                <h1>Ignoring Touchscreen</h1>
                <p>
                    A key reason to BlackBerry’s failure is their ignorance in
                    choosing to stick with the QWERTY keyboard whilst leaders
                    like Apple provided a full touchscreen interface to which
                    consumers preferred. Although previously BlackBerry’s had
                    been popular due to their ease of use with instant messaging
                    and firing off quick emails through the keyboard and
                    scrolling ball.
                </p>
            </div>
            <div className="content-section alt">
                <img src={image2} className={"side-image"} alt="" />
                <h1>Bad Designs</h1>
                <p>
                    Another major issue with BlackBerry was its horrible phone
                    design. BlackBerry showcase their resistance to change. They
                    maintain a keyboard within the phone that causes an increase
                    in weight.
                </p>
            </div>
            <div className="content-section">
                <h1>BBM Stubbornness</h1>
                <p>
                    BBM was one of the keys in the success of Blackberry which
                    includes Features such as Status updates, pinging a user and
                    groups were really interesting at a time where instant
                    messaging was limited. Yet when BlackBerry started
                    freefalling through the market, they thought BBM would save
                    them. Rather than let BBM be installed on other devices,
                    they continued to make it a blackberry exclusive. They
                    eventually changed their mind but by then it was too late —
                    Whatsapp, Kik and iMessage were already dominating.
                </p>
            </div>
            <div className="content-section">
                <h1>Insistence on BlackBerry’s OS</h1>
                <p>
                    Smartphones became a huge thing because of the ability to
                    install any kind of app you want and transform your phone to
                    something magical instantly. From Games to productivity
                    apps, the Appstore provides a plethora of options to users.
                    But with BlackBerry’s OS you couldn’t do this and even today
                    there is only a small portion of apps available. Apps like
                    Facebook and Twitter on the BlackBerry were also difficult
                    to use and you only got half a screen to view the content.
                </p>
            </div>
            <div className="content-section">
                <img src={image3} alt="" />
            </div>
            <div className="content-section">
                <p>
                    According to the company’s latest announcement, legacy
                    services and software will no longer work either on Wi-Fi
                    connections or cellular. Users having classic Blackberry
                    devices running on BlackBerry 7.1 OS and earlier, BlackBerry
                    10 software, BlackBerry PlayBook OS 2.1 and earlier versions
                    will stop working. However, Blackberry smartphones running
                    on Android OS will not be impacted, until and unless a user
                    continues to use Blackberry's email services as they will
                    have to migrate to a new service.
                </p>
            </div>
        </div>
    );
}

export default Content;
