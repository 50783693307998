import React from "react";

import "./TimelineCard.css";

function TimelineCard(props) {
    const { timelineData } = props;
    const { time, title, subTitle, duration, active } = timelineData;

    return (
        <div className={"timeline-card"}>
            <p className={"timeline-time"}>{time}</p>
            <div className={`timeline-circle ${active ? "active" : ""}`} />
            <p className={"timeline-title"}>{title}</p>
            {subTitle && <p className={"timeline-subtitle"}>{subTitle}</p>}
            <p className={"timeline-duration"}>{duration}</p>
        </div>
    );
}

export default TimelineCard;
