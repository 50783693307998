import React from "react";
import image1 from "../../../../assets/img/blogs/unicorn/image1.png";
import image2 from "../../../../assets/img/blogs/unicorn/image2.png";

import "../style.css";
import LabeledImage from "../../../../Components/LabeledImage/LabeledImage";

function Content(props) {
    return (
        <div className={"blog-content-sections"}>
            <div className="content-section">
                <p>
                    The trends from 2021 were clearly sided towards the global
                    emergence of India’ startup ecosystem. With a total of 43
                    startups entering the, now popular, Unicorn Club , i.e.
                    organisations with a valuation of more than $1 billion,
                    clearly shows the interest of Indian consumers & producers
                    towards the entrepreneur-favoured future that lies ahead.
                    India witnessed its first healthtech, social commerce,
                    crypto and epharmacy unicorn this year. The most interesting
                    part was that the entire nation was under a pandemic when
                    these startups bloomed. Some of them even hit their rock
                    bottoms in 2020 but bounced back with a bang.
                </p>
            </div>
            <div className="content-section alt">
                <LabeledImage
                    className={"side-image"}
                    image={image1}
                    label={
                        "All Brands that entered the Unicorn club in 2021. © Inc42"
                    }
                />
                <p>
                    For a wider picture, Indian startups raised almost $36
                    billion in total, according to data provided by Inc42 ,
                    which is a record in itself. This graphic from Economic
                    Times shows a clear comparison in the Venture Capital deals
                    in India, which is the biggest source of these funding
                    rounds. 2021 won by almost three-folds.
                </p>
            </div>
            <div className="content-section">
                <img src={image2} alt="" />
            </div>
            <div className="content-section">
                <p>
                    These monetary advantages have enabled the startups to boost
                    their execution & expansion plans. With all these benefits
                    in hand, the founders have a clear path for their visions,
                    thus leading India into a startup-focused India. But the key
                    outcome from this funding boom & startup success stories is
                    the excited young folks who don’t want to limit their lives
                    for a 9 to 5 job, but are interested to put in their 24
                    hours of a day to an idea they have envisioned.
                </p>
            </div>
            <div className="content-section alt">
                <p>
                    We at Industry and Entrepreneurship Cell, NIT Kurukshetra have inducted freshers
                    this year and we are very thrilled to have people among us
                    who, at such an early stage of their lives, have a clear
                    mindset of what they want to explore in their lives and how
                    can they utilize this lockdown period to develop MVPs for
                    their out of the world ideas and even move on to next stages
                    like User Testing, etc.
                </p>
            </div>
            <div className="content-section">
                <p>
                    Here’s a takeaway for you! Can you guess the three decacorns
                    in India, startups with $10bn valuation ? Without googling
                    ofcourse .
                </p>
            </div>
        </div>
    );
}

export default Content;
