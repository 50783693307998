import React from "react";
import Section from "../../Section/Section";

function EventSponsors(props) {
    const { sponsors, eventRefs } = props;

    return (
        <Section
            className={"event-sponsors"}
            heading={"Event Sponsors"}
            refId={1}
            eventRefs={eventRefs}
        >
            <div className="sponsors">
                {sponsors.map((sponsor, i) => (
                    <a
                        className="sponsor-card"
                        key={i}
                        href={sponsor.link}
                        target="_blank"
                    >
                        <img
                            onClick={() => window.open(sponsor.link, "_blank")}
                            className={"sponsor-img"}
                            src={sponsor.logo}
                            alt={sponsor.name}
                        />
                    </a>
                ))}
            </div>
        </Section>
    );
}

export default EventSponsors;
