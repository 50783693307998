import React from "react";

import "./SocialIcon.css";

function SocialIcon({ icon, link, ...props }) {
    return (
        <a
            href={link}
            target={"_blank"}
            rel="noreferrer"
            className={"social-icon flex-row"}
        >
            {icon}
        </a>
    );
}

SocialIcon.defaultProps = {
    link: "/",
};

export default SocialIcon;
