import React from "react";
import "../style.css";

function Content(props) {
    return (
        <div className={"blog-content-sections"}>
            <div className="content-section">
                <p>
                    "Start your online business" has been the most used acronym
                    since the start of the lockdown. Did you come across it? You
                    might have. Whether or not you want to start, it has been
                    the hottest topic to discuss anyway. People generally talk
                    about the results but ignore the struggles behind them. You
                    need an idea, skilled team, resources, guidance, and
                    whatnot. On top of everything, you need a strong mindset. If
                    you are looking to start one for yourself, this article is
                    for you.
                </p>
            </div>
            <div className="content-section">
                <p>
                    Hello, Everyone! I am Praneet Brar, from Computer Science
                    Branch, 3rd year. During the lockdown, I started my online
                    business{" "}
                    <a href="https://www.google.com/url?q=https://crypto-guide.in/&source=gmail-html&ust=1643948426702000&usg=AOvVaw0z8gz3Ivr61QH3VS21wM4n">
                        "The Crypto Guide"
                    </a>
                    . If you are interested in crypto, you should check the{" "}
                    <a href="https://crypto-guide.in/">website</a> out. The
                    Crypto guide is simply a guide made for crypto enthusiasts
                    which helps them safeguard their crypto journey to the moon.
                    It could be helpful for you if you are tired of losing money
                    in crypto. In this article, I will share my journey with you
                    so far. The first thing that comes to mind, before even
                    starting is the idea, now the question arises,
                </p>
            </div>

            <div className="content-section">
                <h1>How did the idea come?</h1>
                <p>
                    When classes went online, or I should say exams. I got a lot
                    of time, so I wanted to do something productive. I started
                    freelancing, very soon I realized, it was not my thing. But
                    I had completed a few freelance projects, whose payment I
                    received in crypto. I didn't know at that time, I observe
                    some changes in my wallet's balance every day. I started
                    doing my research about it, and I got introduced to crypto.
                    This was so fun, I went deeper and deeper with my research.
                    Took me some months to realize that if all this research
                    work that I did, is put in one place, it would become easier
                    for everyone. That's when the idea came, and I prepared a
                    digital informational product called the crypto guide.
                    <a href="http://crypto-guide.in/products/crypto-guide/">
                        Visit Here
                    </a>
                    .
                </p>
            </div>
            <div className="content-section alt">
                <h1>How did I start?</h1>
                <img
                    src={
                        "https://provenience.in/wp-content/uploads/2019/03/Business-registration.jpg"
                    }
                    className={"side-image"}
                    alt=""
                />
                <p>
                    Now, I have the idea. I do not want this idea to fade away
                    from my mind, so I started working on it. I purchased the
                    domain and the web hosting for the same. and then started
                    with WordPress. Took me about 3 months to complete the
                    development and design of the website.
                </p>
                <p>
                    This looks easy but actually is not. You need skills if you
                    don't have, either you develop, or you hire someone that
                    has. So you need expenditure based on your requirements. For
                    that, either you convince your parents, do some work and
                    earn by yourself, or find an investor. Also, there are
                    several tools that are required, which are also not free to
                    use. So there are a lot of things that you realize only when
                    you start. Most people give up here
                </p>
            </div>

            <div className="content-section">
                <h1>What's next?</h1>
                <p>
                    When you have your business model developed and functional.
                    Then comes the most important and difficult part, "Build
                    your business". You need visitors to your website. You need
                    to generate leads and sales. Marketing is the answer, but
                    this is a huge field in itself. There are so many ways you
                    can do it, but none of them guarantee success. Easy
                    marketing is via google ads. If you have so much money to
                    spend on advertising, then you can. Otherwise, it is a real
                    pain. The strategies, in my opinion, the best and absolutely
                    free are:
                    <ul>
                        <li>Backlinks to other websites in your niche.</li>
                        <li>Cold Email Outreach to the targeted audience.</li>
                        <li>Email marketing automation.</li>
                    </ul>
                </p>

                <h1>Looking forward to start?</h1>
                <p>
                    If you are the one, looking forward to starting your
                    journey, take the step and do what you like, and carry no
                    regrets for later. There are a lot of people to help. Talk
                    with your seniors and like-minded people. If you want to
                    contact me, feel free to do so. Email me at{" "}
                    <strong>praneet@crypto-guide</strong> .in I'll reply within
                    24 hours.
                </p>
            </div>
        </div>
    );
}

export default Content;
