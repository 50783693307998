import React from "react";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";
import "./TeamMemberCard.css";
function TeamMemberCard({ image, name, position, email, linkedin }) {
    const im1 = require("../../../assets/img/team/" + image);
    return (
        <>
            <div className="teamMemberCard">
                <div>
                    <img
                        src={im1}
                        alt="Team Member"
                        className="teamMemberPic"
                    />
                    <div className="teamDetail">
                        <div className="teamMemberName">{name}</div>
                        <div className="teamMemberPosition">{position}</div>
                    </div>
                </div>

                <div>
                    <a href={`mailto:${email}`}>
                        <FaEnvelope className="team-icons" />{" "}
                    </a>
                    <a href={linkedin}>
                        <FaLinkedin className="team-icons" />{" "}
                    </a>
                </div>
            </div>
        </>
    );
}

export default TeamMemberCard;
