import React, { useEffect, useState, useRef } from "react";
import FOG from "vanta/dist/vanta.fog.min.js"
import * as THREE from "three";
import { scrollPercent } from './scrollPercent';
import { TbPodium, TbTrophy, TbManualGearbox, TbRocket } from 'react-icons/tb'
import styles from "./ESLanding.module.css";
import Navbar from "../../Components/Navbar/Navbar";
import esummitLogo from "../../../assets/img/esummit.png"
import icellLogo from "../../../assets/img/LogoBlack.png";
import { Link } from "react-router-dom";

function ESLanding(props) {

    const vantaRef = useRef(null);
    const [vantaEffect, setVantaEffect] = useState(0);
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                FOG({
                    el: vantaRef.current,
                    THREE: THREE,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 600.00,
                    minWidth: 600.00,
                    highlightColor: "#434343",
                    midtoneColor: "#62088f",
                    lowlightColor: "#931bb1",
                    baseColor: "#000000",
                    blurFactor: 0.90,
                    speed: 2.80,
                    zoom: 0.80
                })
            );
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);

    useEffect(() => {
        scrollPercent()
    });
    useEffect(() => {
        document.body.className = styles.bodyClass;
        return ()=>{
            document.body.className = styles.normalClass;
        }
    }, []);
    return (
        <div ref={vantaRef} style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
            <div data-aos="fade-left" id='parentCont' className={styles.container}>
                <Navbar logo={icellLogo} home="/" />
                <canvas className="first" style={{
                    maxHeight: "100vh",
                    maxWidth: "100vw",
                    overflow: "hidden",
                    zIndex: -1,
                    position: "fixed"
                }}></canvas>
                <section className={styles.childSection}>
                    <div className={styles.mainHeading}>
                        <div>
                            <span style={{ fontSize: "1rem", letterSpacing: "0.3rem", }}>THIS JANUARY</span>
                            <h1>WITNESS POSSIBILITIES LIKE NEVER BEFORE</h1>
                        </div>
                    </div>
                </section>
                <section className={styles.childSection}>
                    <div className={styles.mediaContainer}>
                        <div className={styles.mediaVideo}>
                            <iframe className={styles.youtubeVideo} src="https://www.youtube-nocookie.com/embed/dhdDJkZJ72w?rel=0;controls=0;showinfo=0" title="E-Summit NIT KKR" frameBorder="0" allow="accelerometer; autoplay=1;controls=0;encrypted-media; gyroscope; picture-in-picture;fs=0" ></iframe>
                        </div>
                    </div>
                </section>
                <section className={styles.childSection}>
                    <div className={styles.infoContainer}>
                        <div className={styles.right}>
                            <div className={styles.ellipse}>
                                <TbTrophy className={styles.ellipseIcon} />
                            </div>
                            <div className={styles.infoHeading}>
                                <h1>COMPETITIONS</h1>
                            </div>
                            <div className={styles.infoContent}>
                                Indulge in exciting hackathons, case studies, business plans
                            </div>
                            <div className={styles.btnContainer}>
                                <button className={styles.btnContainerButton}><Link to="competitions">KNOW MORE</Link></button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.childSection}>
                    <div className={styles.infoContainer}>
                        <div className={styles.left}>
                            <div className={styles.ellipse}>
                                <TbPodium className={styles.ellipseIcon} />
                            </div>
                            <div className={styles.infoHeading}>
                                <h2>SPEAKER SESSIONS</h2>
                            </div>
                            <div className={styles.infoContent}>
                                Insightful sessions from industry experts
                            </div>
                            <div className={styles.btnContainer}>
                                <button className={styles.btnContainerButton}><Link to="speakers">KNOW MORE</Link></button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.childSection}>
                    <div className={styles.infoContainer}>
                        <div className={styles.right}>
                            <div className={styles.ellipse}>
                                <TbRocket className={styles.ellipseIcon} />
                            </div>
                            <div className={styles.infoHeading}>
                                <h2>STARTUP-EXPO</h2>
                            </div>
                            <div className={styles.infoContent}>
                                <p>Raise funding, hire top talent</p>
                            </div>
                            <div className={styles.btnContainer}>
                                <button className={styles.btnContainerButton}><Link to="startupexpo">KNOW MORE</Link></button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.childSection}>
                    <div className={styles.aboutCont}>
                        <h2>WELCOME TO E-SUMMIT 2023</h2>
                        <h5>January 26-29, 2023</h5>
                        E-Summit is NIT Kurukshetra's first ever entrepreneurial symposium for college students. It will witness a wide range of activities like competitions, speaker sessions, workshops and events like startup expo. So what are you waiting for? Come, hop on this journey and witness the <i>Prism of Possibilities!</i>
                    </div>
                </section>

                <div className={styles.footCont}>
                    <div className={styles.imgCont}>
                        <img className={styles.footerLogo} src={esummitLogo} alt="ESummit NIT KKR Logo"></img>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ESLanding;

