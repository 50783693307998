import React from 'react'
import {  TbRocket } from 'react-icons/tb';
import "./startupexpo.css"
import { BsAward, BsCash, BsCheck2Circle, BsPeople } from 'react-icons/bs';
export default function StartupExpo() {
    return (
        <>
            <section className="startupExpoSection">
                <div className="startupExpoIcon">
                    <TbRocket />
                </div>
                <h1 className="ESLayoutSectionHeading">STARTUP EXPO</h1>
                <h4 style={{ fontWeight: "100" }}>A PLATFORM FOR EARLY STAGE STARTUPS</h4>
                <p>Startup expo is the perfect platform for connecting budding early stage startups with investors and secure funding. Founders can also hire students from various participating institutes in their startup. Register now for Startup Expo and kickstart the journey of your startup's success!
                </p>
                <button className="esbutn"><a href="https://forms.gle/j8CL4Bjj8nB4BXkGA" rel="noreferrer" target="_blank">REGISTER</a></button>
            </section>
            <section className="startupExpoSection">
                <h2 style={{ fontWeight: 500 }}>WHAT'S IN FOR YOU?</h2>
                <div className="startupExpoFeatCont">
                    <div className="startupExpoFeature">
                        <BsCash style={{fontSize: "6rem",margin:"auto"}} />
                        <h4 className="startupExpoFeatureHeading">Secure funding from investors</h4>
                    </div>
                    <div className="startupExpoFeature">
                        <BsPeople style={{fontSize: "6rem",margin:"auto"}} />
                        <h4 className="startupExpoFeatureHeading">Publicize your product/service to a wide audience.</h4>
                    </div>
                    <div className="startupExpoFeature">
                        <BsCheck2Circle style={{fontSize: "6rem",margin:"auto"}} />
                        <h4 className="startupExpoFeatureHeading">Hire top talents from participating institutes</h4>
                    </div>
                    <div className="startupExpoFeature">
                        <BsAward style={{fontSize: "6rem",margin:"auto"}} />
                        <h4 className="startupExpoFeatureHeading">Best startup award</h4>
                    </div>
                </div>
            </section>
        </>
    );
}
