import React from "react";
import CarouselItem from "./CarouselItem";
import useResponsiveCarousel from "./hooks/useResponsiveCarousel";

function Slider({ active, setActive, ...props }) {
    const [currentSlideWidth, refs] = useResponsiveCarousel(
        active,
        setActive,
        props.children
    );

    return (
        <div className={"carousel-container"}>
            <div
                className={"slider flex-row"}
                style={{
                    transform: `translateX(${currentSlideWidth}px)`,
                }}
            >
                {React.Children.map(props.children, (child, i) => (
                    <CarouselItem active={i === active} passedRef={refs[i]}>
                        {React.cloneElement(child)}
                    </CarouselItem>
                ))}
            </div>
        </div>
    );
}

export default Slider;
