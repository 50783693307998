import React from "react";

import "./Footer.css";

import FooterInfo from "./FooterInfo";
import FooterNewsletter from "./FooterNewsletter";

function Footer(props) {
    return (
        <section id={"footer"} className={"flex-col"}>
            <div className={"footer flex-row"}>
                <FooterInfo />
                <FooterNewsletter />
            </div>
            <p className={"copyright"}>
                &copy;
                {` ${new Date().getFullYear()}-${
                    new Date().getFullYear() + 1
                } All Rights Reserved | Designed by Startup Cell,
                NIT KURUKSHETRA`}
            </p>
        </section>
    );
}

export default Footer;
