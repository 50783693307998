import React from "react";
import image1 from "../../../../assets/img/blogs/amongus/image1.jpg";
import image2 from "../../../../assets/img/blogs/amongus/image2.png";
import image3 from "../../../../assets/img/blogs/amongus/image3.jpg";

import "../style.css";

function Content(props) {
    return (
        <div className={"blog-content-sections"}>
            <div className="content-section">
                Taking inspiration from a live game “Mafia” and science fiction
                horror film “The Thing”, Indie developers InnerSloth launched
                their online multiplayer murder mystery mobile game “Among Us”
                that runs on Unity Engine. It was first released on June 15,
                2018 for iOS and Android and was later released on Steam in
                August 2018
            </div>
            <div className="content-section">
                <img className={"side-image"} src={image1} alt="" />
                <p>
                    The game is set on one of four locations; a spaceship, above
                    earth base, planet base, or a Henry-Stickmin-themed Airship
                    which contains a group of crew members, among which are upto
                    three Imposters who have to Sabotage the ship or base and
                    kill off the crew.
                </p>
            </div>
            <div className="content-section">
                With hardly 50-100 regular users in the first year, the game hit
                a new record with “60 million” daily active users and “3.3
                million” concurrent users by the end of September 2020. Youtube
                reported that videos about Among Us were viewed 4 billion times
                in September2020 and it has been installed more than 324 times
                according to app Annie data. Among Us grew its user base by
                1600%in 8 months.
            </div>
            <div className="content-section alt">
                <img className={"side-image"} src={image2} alt="" />
                <p>
                    The rapid increase in the popularity of Among Us can be
                    chalked up to the simplicity of its design. In Fact anyone
                    can learn the core mechanics in about 10 minutes. Moreover
                    the game provides an opportunity to socialize with others
                    online while remaining socially distanced in the Lockdown
                    period. The studio received 6 accolades and awards over the
                    last two years that includes “Best Multiplayer and Mobile
                    game” in 2020 , “Kids Choice Award for favorite Video Game”
                    and “Webby Breakout of the year Award in 2021.
                </p>
            </div>
            <div className="content-section">
                <img className={"side-image"} src={image3} alt="" />
                <p>
                    Among Us success was short term because of lack of regular
                    updates, no voice chats, less room size, no new roles and
                    other gaming features. Moreover the Game was not designed to
                    maintain prolonged interest. The game’s meteoric rise in
                    popularity was completely unexpected and the small team of
                    Innersloth was unable to sustain quick updates necessary for
                    maintaining its popularity. Earlier Innersloth was planning
                    to build a standalone Among Us sequel that could host the
                    original version of the game within it since Among Us was
                    built on old tech and at this point iterating on the game’s
                    core infrastructure is a challenging task.
                </p>
            </div>
            <div className="content-section">
                However in the weeks, Innersloth announced that they would be
                canning the plans for a sequel entirely and Among Us 2 would be
                integrated into the primitive code, no matter how challenging it
                is.
            </div>
        </div>
    );
}

export default Content;
