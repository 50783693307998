import React from "react";

function GoogleMap(props) {
    return (
        <div className={"google-map"}>
            <iframe
                title={"startup-cell-gmap-loc"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1774686.9881467086!2d76.6461967!3d29.68540045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390e3f51b801e9ef%3A0x726eee4c88786c34!2sIndustry%20CELL%20NIT%20Kurukshetra!5e0!3m2!1sen!2sin!4v1634023973979!5m2!1sen!2sin"
                height="300"
                style={{ border: "0", width: "100%" }}
                allowFullScreen=""
                loading="lazy"
            />
        </div>
    );
}

export default GoogleMap;
