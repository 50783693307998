import { React, useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import FOG from "vanta/dist/vanta.fog.min.js"
import * as THREE from "three";
import Navbar from "./Components/Navbar/Navbar";
import esummitLogo from "../assets/img/esummit.png"
import "./ESLayout.css"
function ESLayout(props) {

    const vantaRef = useRef(null);
    const [vantaEffect, setVantaEffect] = useState(0);
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                FOG({
                    el: vantaRef.current,
                    THREE: THREE,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 600.00,
                    minWidth: 600.00,
                    highlightColor: "#434343",
                    midtoneColor: "#62088f",
                    lowlightColor: "#931bb1",
                    baseColor: "#000000",
                    blurFactor: 0.90,
                    speed: 2.80,
                    zoom: 0.80
                })
            );
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);

    useEffect(() => {
        document.body.className = "bodyClass";
        return () => {
            document.body.className = "normalClass";
        }
    }, []);
    return (
        <div className="ESLayoutContainer" ref={vantaRef}>
            <Navbar logo={esummitLogo} home="" />
            <section className="ESLayoutSection">
                <Outlet />
            </section>
            {/* Place footer here */}

        </div>

    );
}

export default ESLayout;
