/* eslint-disable no-unused-vars */
import React, { useContext, useRef } from "react";
import DoubleHeading from "../../Components/DoubleHeading/DoubleHeading";
import EventCard from "../../Components/EventCard/EventCard";
import { BsCode } from "react-icons/bs";
import { BiTrophy } from "react-icons/bi";
import { AiOutlineStock } from "react-icons/ai";
import { MdHardware } from "react-icons/md";
import { TbBusinessplan } from "react-icons/tb";
import { RiExchangeFundsLine,RiTreasureMapLine, RiPaintLine, RiTimerLine } from "react-icons/ri";
import NavScrollContext from "../../Contexts/NavScrollContext";

const events = [
    {
        route: "productathon",
        name: "Productathon",
        description: "Hardware and Software based Design Sprint",
        icon: <MdHardware />,
    },
    {
        route: "tradehunt",
        name: "Trade Hunt",
        description:
            "Stock Makret competition Simulation Contest with initial money worth ₹10,00,000",
        icon: <AiOutlineStock />,
    },
    {
        route: "bplan",
        name: "B-Plan",
        description:
            "An Event to Pitch Innovative Ideas and Show Entrpreneurial Skills",
        icon: <TbBusinessplan />,
    },
    // {
    //     route: "youngleaders",
    //     name: "Young leaders",
    //     description: "Pitching solutions to the UN's 16 Sustainable Goals",
    //     icon: <BiTrophy />,
    // },
    {
        route: "bquiz",
        name: "B-Quiz",
        description: "A fun trivia based on business, technology & entertainment",
        icon: <RiTimerLine />,
    },
    // {
    //     route: "designathon",
    //     name: "Designathon",
    //     description: "Ace your UI/UX skills",
    //     icon: < RiPaintLine/>,
    // },
    {
        route: "corporatehunt",
        name: "Corporate Hunt",
        description: "A scavenger hunt where participants collide to solve cryptic puzzles and brain teasers related to Entrepreneurship and start-up culture.",
        icon: < RiTreasureMapLine/>,
    },
    // {
    //     route: "bidbizz",
    //     name: "Bid-bizz",
    //     description: "Event for company valuation",
    //     icon: < RiExchangeFundsLine/>,
    // },
];

function Events(props) {
    const navRefs = useContext(NavScrollContext);

    const ref = useRef(null);
    navRefs.current.events = ref;

    return (
        <section id={"home--events"} className={"flex-col"} ref={ref}>
            <DoubleHeading label={"Events"} />
            <div className={"events-container flex-row"}>
                {events.map((event, i) => (
                    <EventCard key={i} id={event.route} {...event} />
                ))}
            </div>
        </section>
    );
}

export default Events;
