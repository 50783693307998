import React from "react";
import "./TeamName.css";
import TeamNameCard from "../TeamNameCard/TeamNameCard";
import teamTech from "../../../assets/img/team/teamName/team_tech.webp";
import teamMedia from "../../../assets/img/team/teamName/team_media.webp";
import teamCorporate from "../../../assets/img/team/teamName/team_corporate.webp";

const teamName = [
    {
        title: "Media and Marketing",
        img: teamMedia,
    },
    {
        title: "Technical",
        img: teamTech,
    },
    {
        title: "Corporate Relations",
        img: teamCorporate,
    },
];

function TeamName({changeTeam, ...props}) {
    return (
        <div className="TeamNameDiv">
            {teamName.map((name, i) => (
                <TeamNameCard key={i} src={name.img} title={name.title} id={i} changeTeam={changeTeam}/>
            ))}
        </div>
    );
}

export default TeamName;
