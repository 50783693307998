import React, { useEffect, useRef } from "react";
import "./BlogList.css";
import BlogFeatCard from "./BlogFeatCard";
import { FaSearch } from "react-icons/fa";
import BlogListCard from "./BlogListCard";
import { gsap, Power3 } from "gsap";
import { Link } from "react-router-dom";

import blogs from "../../Screens/Blogs/blogs.js";

function BlogPage() {
    let blogListPageContainer = useRef(null);
    let blogTopHead = useRef(null);
    let blogTopDesc = useRef(null);
    useEffect(() => {
        //to make animation smooth
        blogListPageContainer.style.visibility = "visible";

        //animation
        gsap.from(blogTopHead, {
            opacity: 0,
            y: 40,
            duration: 1,
            ease: Power3.easeOut,
        });
        gsap.from(blogTopDesc, {
            opacity: 0,
            y: 40,
            duration: 1,
            ease: Power3.easeOut,
            delay: 0.3,
        });
        gsap.from(".animateBlogElements", {
            duration: 2,
            opacity: 0,
            y: 50,
            ease: Power3.easeOut,
            delay: 0.6,
        });
    }, []);

    const feat_left_data = [
        {
            Category: "Marketing",
            Description:
                "The power of Influencer Marketing on Social Media Posts",
        },
    ];
    const feat_right_data = [
        {
            Category: "FINANCE",
            Description: "World Bank Facing criticism over pro-China tips",
        },
        {
            Category: "Health",
            Description: "WHO on alert over Omicron spread",
        },
    ];

    const tags_data = [
        {
            tag: "Marketing",
        },
        {
            tag: "Finance",
        },
        {
            tag: "Health",
        },
        {
            tag: "Among US",
        },
        {
            tag: "Unicorn",
        },
        {
            tag: "Blackberry",
        },
    ];

    return (
        <div
            ref={(e) => (blogListPageContainer = e)}
            className="blog-list-page-container"
        >
            <div className="blog-list-top">
                <div>
                    <h1 ref={(e) => (blogTopHead = e)}>Startup Cell Blogs</h1>
                    <h3 ref={(e) => (blogTopDesc = e)}>
                        Read . <span>Learn</span> . Achieve
                    </h3>
                </div>
            </div>
            <section className="blog-list-container">
                <div className="featured-section">
                    <div className="feat-top animateBlogElements">
                        <h4>Featured</h4>
                        <div className="blog-list-search">
                            <button>
                                <FaSearch />
                            </button>
                            <input
                                type="text"
                                placeholder="Search for any blog"
                            />
                        </div>
                    </div>
                    <div className="feat-middle">
                        <div className="feat-left">
                            {feat_left_data.map((e, i) => {
                                return (
                                    <BlogFeatCard
                                        key={i}
                                        Category={e.Category}
                                        Description={e.Description}
                                    />
                                );
                            })}
                        </div>
                        <div className="feat-right">
                            {feat_right_data.map((e, i) => {
                                return (
                                    <BlogFeatCard
                                        key={i}
                                        Category={e.Category}
                                        Description={e.Description}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="blog-list-other-container">
                    <div className="blog-list-card-container">
                        {blogs.map((e) => {
                            return (
                                <BlogListCard
                                    key={e.route}
                                    route={e.route}
                                    title={e.shortHeading}
                                    author={e.authorDetails.name}
                                    desc={
                                        e.desc.length > 100
                                            ? e.desc.substring(0, 100) + "..."
                                            : e.desc
                                    }
                                    image={e.bannerImg}
                                    date={e.date}
                                    readTime={e.readTime}
                                    tag={e.tags?.[0] || ""}
                                />
                            );
                        })}
                    </div>
                    <div className="blog-list-tags ">
                        <h1 className="">Discover more categories</h1>
                        <div>
                            {tags_data.map((e, i) => {
                                return (
                                    <Link
                                        to={"#"}
                                        key={i}
                                        className="blog-list-tag"
                                    >
                                        {e.tag}
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BlogPage;
