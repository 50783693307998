import React from "react";

import LeftSection from "./LeftSection";
import RightSection from "./RightSection";

import "../Blog.css";

function BlogPage(props) {
    const { blog } = props;

    return (
        <div className={"blog-page"}>
            <LeftSection
                authorDetails={blog.authorDetails}
                likes={blog.likes}
            />
            <RightSection
                authorDetails={blog.authorDetails}
                banner={blog.bannerImg}
                heading={blog.heading}
                tags={blog.tags}
                content={blog.content}
            />
        </div>
    );
}

export default BlogPage;
