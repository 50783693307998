import React from "react";
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navigation/Navigation";
import { Outlet } from "react-router-dom";

function Layout(props) {
    return (
        <div style={
            { fontFamily: ["Sora", "sans-serif"]}
        }>
            <Navigation />
            <Outlet />
            <Footer />
        </div>
    );
}

export default Layout;
