import React from "react";
import Button from "../Button/Button";

import "./Inputs.css";
import Input from "./Input";

function InputWithBtn({ placeholder, label, type }) {
    return (
        <div className={"input-btn-pair flex-row"}>
            <Input type={type} placeholder={placeholder} />
            <Button round={false} label={label} />
        </div>
    );
}

export default InputWithBtn;
