import React from "react";

import design from "../../assets/img/design1.svg";

import "./DesignContainer.css";

function DesignContainer(props) {
    return (
        <div className={"design-container"}>
            <img src={design} alt="" />
            {props.children}
        </div>
    );
}

export default DesignContainer;
