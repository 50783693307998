import React from "react";
import { Link } from "react-router-dom";

export default function BlogListCard(props) {
    return (
        <Link className="listcard" to={`${props.route}`}>
            <div className="listcard-left">
                <h1>{props.title}</h1>
                <span className="listcard-Author">by {props.author}</span>
                <p>{props.desc}</p>
                <div>
                    <span> {props.date} </span>
                    <span>.</span>
                    <span> {props.readTime} </span>
                    <span>.</span>
                    <span className="listcardtag"> {props.tag} </span>
                </div>
            </div>
            <div className="listcard-right">
                <img src={props.image} alt="" />
            </div>
        </Link>
    );
}
