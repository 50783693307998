import React, { forwardRef } from "react";

import "./Button.css";
import { useNavigate } from "react-router-dom";

const Button = forwardRef(({ label, link, round, onClick, ...props }, ref) => {
    const navigate = useNavigate();

    const handleClick = () => {
        link && navigate(link);
        onClick && onClick();
    };

    return (
        <div
            className={"btn" + (round ? " btn-round" : "")}
            ref={ref}
            onClick={handleClick}
            {...props}
        >
            {label}
        </div>
    );
});

Button.defaultProps = {
    round: true,
    label: "Button",
};

export default Button;
