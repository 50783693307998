import React, { useRef } from "react";
import SecondaryNavBar from "../../SecondaryNavBar/SecondaryNavBar";
import EventAbout from "./EventAbout";
import EventRules from "./EventRules";
import EventTimeline from "./EventTimeline";
import EventFaQs from "./EventFAQs";
import EventGallery from "./EventGallery";
import ButtonedImage from "../../ButtonedImage/ButtonedImage";

import "../Event.css";
import scrollToViewOffset from "../../../util/scrollToViewOffset";
import Button from "../../Button/Button";
import EventSponsors from "./EventSponsors";


const navList = [
    {
        label: "About",
        class: ".event-about",
    },
    {
        label: "Rules",
        class: ".event-rules",
    },
    {
        label: "Timeline",
        class: ".event-timeline",
    },
    {
        label: "FAQ",
        class: ".event-faqs",
    },
    {
        label: "Gallery",
        class: ".event-gallery",
    },
];
const navListWithSponsors = [
    {
        label: "About",
        class: ".event-about",
    },
    {
        label: "Sponsors",
        class: ".event-sponsors",
    },
    {
        label: "Rules",
        class: ".event-rules",
    },
    {
        label: "Timeline",
        class: ".event-timeline",
    },
    {
        label: "FAQ",
        class: ".event-faqs",
    },
    {
        label: "Gallery",
        class: ".event-gallery",
    },
];
function EventDetails(props) {
    const { eventData } = props;
    const { name, banner, rules, about, timelines, faqs, cta, link, sponsors, gallery} =
        eventData;
    const eventRefs = useRef({});

    const handleClick = (id) => {
        const el = eventRefs?.current?.[id]?.current;
        if (el) scrollToViewOffset(el, 110);
    };

    const bannerSrc = require("../../../assets/img/events/" + banner);
    return (
        <div className={"event-details"}>
            <ButtonedImage link={link} image={bannerSrc} label={"Register"} />
            <div className="header-row">
                <h1>{name}</h1>
                {cta && (
                    <Button
                        onClick={() => window.open(cta.link, "_blank")}
                        label={cta.label}
                    ></Button>
                )}
            </div>
            <SecondaryNavBar
                navList={sponsors?.length > 0 ? navListWithSponsors : navList}
                onClick={handleClick}
            />
            <EventAbout about={about} eventRefs={eventRefs} />
            {sponsors && (
                <EventSponsors sponsors={sponsors} eventRefs={eventRefs} />
            )}
            <EventRules rules={rules} eventRefs={eventRefs} />
            <EventTimeline timelines={timelines} eventRefs={eventRefs} />
            <EventFaQs faqs={faqs} eventRefs={eventRefs} />
            <EventGallery gallery={gallery} eventRefs={eventRefs} />
        </div>
    );
}

export default EventDetails;
