import React from "react";

import "./Inputs.css";

function Input({ type, placeholder }) {
    return <input type={type} placeholder={placeholder} />;
}

Input.defaultProps = {
    type: "text",
    placeholder: "",
};

export default Input;
