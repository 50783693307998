import React from "react";
import "./TeamMember.css";
import TeamMemberCard from "../TeamMemberCard/TeamMemberCard";
import DataFile from "../../../Data/teamMembers.json";

const teamName= [
    "Meet Our Media & Marketing Team",
    "Meet Our Technical Team",
    "Meet Our Corporate Relations Team",
]

function TeamMember({active}) {
    

    return (
        <>  
            <h1 className="teamHeading">{teamName[active]}</h1>
            <div className="teamMemberDiv">
                {DataFile[active].map((detail, i) => (
                    <TeamMemberCard
                        key={i}
                        image={detail.img}
                        name={detail.name}
                        position={detail.position}
                        email={detail.mail}
                        linkedin={detail.linkedin}
                    />
                ))}
            </div>
        </>
    );
}

export default TeamMember;
