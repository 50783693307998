import React, { useContext } from "react";
import TypeAnimation from "react-type-animation";
import Button from "../../Components/Button/Button";
import AosFade from "../../Components/Aos/AosFade";
import NavScrollContext from "../../Contexts/NavScrollContext";
import scrollToViewOffset from "../../util/scrollToViewOffset";

function Welcome(props) {
    const navRefs = useContext(NavScrollContext);

    const handleClick = () => {
        scrollToViewOffset(navRefs?.current?.about?.current);
    };

    return (
        <section id={"home--welcome"} className={"flex-col"}>
            <AosFade duration={600}>
                <div className={"flex-col"}>
                    <h1>
                        Welcome to the Startup Cell <br />
                        NIT Kurukshetra
                    </h1>
                    <TypeAnimation
                        cursor={true}
                        sequence={[
                            2000,
                            "IDEATE",
                            2000,
                            "INNOVATE",
                            2000,
                            "INCUBATE",
                        ]}
                        wrapper="h2"
                        repeat={Infinity}
                    />
                    <Button
                        label={"Get Started"}
                        link={"#home--about"}
                        onClick={handleClick}
                    />
                </div>
            </AosFade>
        </section>
    );
}

export default Welcome;
