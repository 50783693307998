import React from 'react';
import Speaker from '../../Components/Speaker/Speaker';
import "./speakers.css";
import speakers from "../../data/speakers.json";

function Speakers() {
  return (
    <>
    <h1 className="ESLayoutSectionHeading">SPEAKERS</h1>
    <div className="speakersList">
      {
        speakers.map((val,idx)=><Speaker {...val} key={idx}/>)
      }
    </div>
    </>
  );
}

export default Speakers;
