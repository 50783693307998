import React from "react";

import "./Tag.css";

function Tag(props) {
    const { name } = props;

    return <p className={"tag"}>{name}</p>;
}

export default Tag;
