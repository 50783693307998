import React from "react";
import NavLink from "./NavLink";

function NavLinksContainer({ mobileMenuHidden }) {
    return (
        <div
            className={
                "navlinks--container" + (mobileMenuHidden ? "" : " active")
            }
        >
            <NavLink label={"Home"} link={"/"} />
            <NavLink
                label={"About Us"}
                link={"/#home--about"}
                refKey={"about"}
            />
            <NavLink
                label={"Events"}
                link={"/#home--events"}
                refKey={"events"}
            />
            <NavLink label={"Teams"} link={"/#home--team"} refKey={"team"} />
            <NavLink
                label={"Contact"}
                link={"/#home--contacts"}
                refKey={"contacts"}
            />

            <NavLink label={"Blogs"} link={"/blogs"} />
            <NavLink label={"Contributors"} link={"/contributors"} />
            <NavLink label={"E-Summit"} link={"/esummit"} />
        </div>
    );
}

NavLinksContainer.defaultProps = {
    mobileMenuHidden: false,
};

export default NavLinksContainer;
