import React, { useEffect, useState } from "react";

import navIcon from "../../assets/img/newLogo.png";
import NavCaption from "./NavCaption";
import { useLocation } from "react-router-dom";

const navCaptions = {
    blogs: "Digest",
};

function NavIcon({ navigateToHome }) {
    const location = useLocation();

    const [caption, setCaption] = useState(false);

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        setCaption(navCaptions[path] ? navCaptions[path] : false);
    }, [location.pathname]);

    return (
        <div className={"navicon-container flex-row"} onClick={navigateToHome}>
            <img className={"navicon"} src={navIcon} alt="StartupCell" />
            {caption && <NavCaption caption={caption} />}
        </div>
    );
}

export default NavIcon;
