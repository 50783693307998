import React, { useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import "./LikeButton.css";

function LikeButton(props) {
    const { likes, liked: likedVal } = props;

    const [liked, setLiked] = useState(likedVal);

    const handleClick = () => {
        setLiked(!liked);
    };

    return (
        <div className={"like-btn-container"} onClick={handleClick}>
            {liked ? <AiFillHeart /> : <AiOutlineHeart />}
            <span>{likes} likes</span>
        </div>
    );
}

export default LikeButton;
