import React from "react";
import useAosScrollIntoView from "./hooks/useAosScrollIntoView";

function AosApplier({ type, duration, children, onView }) {
    const [refEl, inView] = useAosScrollIntoView();

    return React.Children.map(children, (child, i) =>
        React.cloneElement(child, {
            "data-aos": type,
            "aos-inview": onView ? inView.toString() : "true",
            "aos-duration": duration,
            ref: refEl,
        })
    );
}

AosApplier.defaultProps = {
    duration: 600,
    onView: false,
};

export default AosApplier;
