import React from "react";
import NavLinksContainer from "./NavLinksContainer";

function MinimalNavigation(props) {
    return (
        <nav className="minimal-navigation">
            <NavLinksContainer />
        </nav>
    );
}

export default MinimalNavigation;
