import React from "react";
import Section from "../../Section/Section";

function EventGallery(props) {
    const { gallery, eventRefs } = props;

    return (
        <Section
            className={"event-Gallery"}
            heading={"Gallery"}
            refId={5}
            eventRefs={eventRefs}
        >
            <a href={gallery} target="_blank">Click to view</a>
        </Section>
    );
}

export default EventGallery;
