import React from "react";
import image2 from "../../../../assets/img/blogs/blogimages/img1.png";
import image3 from "../../../../assets/img/blogs/blogimages/img2.jpg";

import "../style.css";

function Content() {
    return (
        <div className="blog-content-sections">
            <div className="content-section">
                <h3>Crude Oil Prices</h3>
                <p>
                    Crude oil prices had been rising even during the build-up of
                    Russia’s President Vladimir Putin’s special military
                    operation in the Soviet-era constituent, Ukraine, backed
                    externally by the US-dominated military alliance called NATO
                    (North Atlantic Treaty Organization). Oil jumped to $139 a
                    barrel at one point, the highest level for almost 14 years,
                    while wholesale gas prices for next-day delivery more than
                    doubled. It came as the US hinted at a ban on buying Russian
                    energy, as it looked to other countries to increase
                    supplies.
                </p>
            </div>

            <div className={"content-section"}>
                <img className={"side-image"} src={image2} alt="" />
                <h3>Why Does Russian Fuel Matter ?</h3>
                <p>
                    Actually, Russia is the world's third-largest oil producer
                    (behind the US and Saudi Arabia). It supplies 14 per cent of
                    global production or 7-8 million barrels per day of crude
                    oil to markets worldwide. The ban by the US and the UK and
                    the decision of some other pro-Ukraine countries not to buy
                    Russian fuel deepen a crisis. The supplies were already
                    tight.
                </p>
            </div>

            <div className={"content-section"}>
                <img className={"side-image"} src={image3} alt="" />
                <h3>Further Hike In Prices</h3>
                <p>
                    The crude oil price may even surpass its record high of
                    $147.50 a barrel. In fact, Russia has warned that the crude
                    oil price may soar to $300 a barrel if the US and European
                    countries completely ban imports of Russian oil.
                </p>
            </div>

            <div className={"content-section"}>
                <h3>India - Russia Deal</h3>
                <p>
                    Taking advantage of a Russian offer to sell its crude oil
                    cheap and bear the cost of insurance and transportation,
                    India may import as much as 2 million tonnes (mt), or
                    roughly 15 million barrels of crude oil. This comes after
                    reports that Indian Oil Corporation (IOC) bought 3 million
                    barrels of Russian Urals from trader Vitol for May delivery.
                </p>
            </div>
        </div>
    );
}

export default Content;
