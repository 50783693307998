import React from "react";
import AuthorDetails from "../AuthorDetails";
import SocialIcon from "../../SocialIcon/SocialIcon";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import LikeButton from "../../LikeButton/LikeButton";

const socialIcons = [
    {
        icon: <FaFacebookF />,
        link: "https://www.facebook.com/industrycell/",
    },
    {
        icon: <AiFillInstagram />,
        link: "https://www.instagram.com/icell.nitkkr/?hl=en",
    },
    {
        icon: <FaLinkedinIn />,
        link: "https://www.linkedin.com/company/industry-cell-nit-kurukshetra/?originalSubdomain=in",
    },
    {
        icon: <FaYoutube />,
        link: "https://youtube.com/channel/UCFRhPkF1zQwwCm9V_fbTJEw",
    },
];

function LeftSection(props) {
    const { authorDetails, likes } = props;

    return (
        <div className="left-section">
            <AuthorDetails {...authorDetails} />
            <div className="btns-wrapper">
                <div className="social-btns">
                    {socialIcons.map((socialIcon, i) => (
                        <SocialIcon key={i} {...socialIcon} />
                    ))}
                </div>
                <LikeButton likes={likes} />
            </div>
        </div>
    );
}

export default LeftSection;
