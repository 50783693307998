import React, { useState } from "react";

import "./Faq.css";
import { BsPlusLg } from "react-icons/bs";

function Faq(props) {
    const { faqData } = props;
    const { que, ans } = faqData;

    const [hidden, setHidden] = useState(true);

    const handleClick = () => {
        setHidden(!hidden);
    };

    return (
        <div className={"faq-card"} onClick={handleClick}>
            <div className="faq-card-header">
                <p className={"faq-que"}>{que}</p>
                <BsPlusLg className={`${hidden ? "" : "cross"}`} />
            </div>

            <p className={`faq-ans ${!hidden ? "show" : ""}`}>{ans}</p>
        </div>
    );
}

export default Faq;
