import React, { useRef } from "react";

import "./Section.css";

function Section(props) {
    const { heading, className, eventRefs, refId } = props;

    const ref = useRef(null);

    if (refId && eventRefs?.current) eventRefs.current[refId] = ref;

    return (
        <div className={`page-section ${className}`} ref={ref}>
            <h1>{heading}</h1>
            {props.children}
        </div>
    );
}

export default Section;
