import React from "react";
import Section from "../../Section/Section";
import Faq from "../../Faq/Faq";

function EventFaQs(props) {
    const { faqs, eventRefs } = props;

    return (
        <Section
            className={"event-faqs"}
            heading={"Frequently Asked Questions"}
            refId={4}
            eventRefs={eventRefs}
        >
            <div className={"faqs"}>
                {faqs.map((faq, i) => (
                    <Faq key={i} faqData={faq} />
                ))}
            </div>
        </Section>
    );
}

export default EventFaQs;
