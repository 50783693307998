import React from "react";
import Section from "../../Section/Section";
import RuleCard from "../RuleCard";

function EventRules(props) {
    const { rules, eventRefs } = props;

    return (
        <Section
            className={"event-rules"}
            heading={"Rules for the event"}
            refId={2}
            eventRefs={eventRefs}
        >
            {rules.map((rule, i) => (
                <RuleCard key={i} number={i + 1} rule={rule} />
            ))}
        </Section>
    );
}

export default EventRules;
