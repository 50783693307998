import React from "react";
import AosApplier from "./AosApplier";

function AosFlip({ direction, duration, children, onView }) {
    return (
        <AosApplier
            type={"flip-" + direction}
            duration={duration}
            onView={onView}
        >
            {children}
        </AosApplier>
    );
}

AosFlip.defaultProps = {
    direction: "left",
};

export default AosFlip;
