import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import "./EventCard.css";
import AosFlip from "../Aos/AosFlip";

function EventCard({ id, name, description, icon, ...props }, ref) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/event/${id}`);
    };

    return (
        <AosFlip direction={"left"} duration={600} onView={true}>
            <div className={"event-card-box"} onClick={handleClick} ref={ref}>
                <div className={"event-card"}>
                    <div className={"event-head"}>
                        <div className={"event-icon"}>{icon}</div>
                        <h3>{name}</h3>
                    </div>
                    <p>{description}</p>
                </div>
            </div>
        </AosFlip>
    );
}

export default forwardRef(EventCard);
