import "./App.css";
import { useRef } from "react";
import NavScrollContext from "./Contexts/NavScrollContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Screens/Layout";
import Home from "./Screens/Home/Home";
import Events from "./Screens/Events/Events.jsx";
import Blogs from "./Screens/Blogs/Blogs.jsx";
import BlogList from "./Components/BlogListPage/BlogList";
import TeamPage from "./Screens/Teams/TeamPage";
import Page404 from "./Screens/Page404/Page404";
import Contributors from "./Screens/Contributors/Contributors";
import Documents from "./Screens/Docs/docs";
import ESLayout from "./ESummit/ESLayout";
import ESLanding from "./ESummit/Screens/Landing/ESLanding";
import ESCompetitions from "./ESummit/Screens/Competitions/ESCompetitions";
import ProgressPage from "./ESummit/Screens/ProgressPage/ProgressPage";
import Schedule from "./ESummit/Screens/Schedule/Schedule";
import StartupExpo from "./ESummit/Screens/StartupExpo/StartupExpo";
import ESSponsors from "./ESummit/Screens/Sponsors/ESSponsors";
import Speakers from "./ESummit/Screens/Speakers/Speakers";

function App() {
    const navRefs = useRef({});

    return (
        <div className="App">
            <Router basename="/">
                <NavScrollContext.Provider value={navRefs}>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path="/event/:id" element={<Events />} />
                            <Route path="/blogs" element={<BlogList />} />
                            <Route path="/blogs/:id" element={<Blogs />} />
                            <Route path="/teams" element={<TeamPage />} />
                            <Route
                                path="/contributors"
                                element={<Contributors />}
                            />
                            <Route path="/docs/:file" element={<Documents />} />
                            <Route path="*" element={<Page404 />} />
                        </Route>
                        {/* Uncomment below when pages are ready */}
                        <Route path="/esummit" element={<ESLanding />} />
                        <Route path="/esummit/*" element={< ESLayout />}>
                        {/*     //TO-DO: add individual screens here with path (without leading slash) */}
                            <Route path="competitions" element={<ESCompetitions/>}/>
                            <Route path="schedule" element={<Schedule/>}/>
                            <Route path="sponsors" element={<ESSponsors/>}/>
                            <Route path="startupexpo" element={<StartupExpo/>}/>
                            <Route path="speakers" element={<Speakers/>}/>
                            <Route path="*" element={<ProgressPage/>}/>
                        </Route>
                    </Routes>
                </NavScrollContext.Provider>
            </Router>
        </div>
    );
}

export default App;
