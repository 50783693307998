import React from "react";

function RuleCard(props) {
    const { number, rule } = props;

    return (
        <div className={"rule-card"}>
            <span>{number}</span>
            <p>{rule}</p>
        </div>
    );
}

export default RuleCard;
