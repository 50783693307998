import React from "react";
import TeamMemberCard from "../../Components/Teams/TeamMemberCard/TeamMemberCard";
import contributors from "../../Data/contributors.json";
import "./Contributors.css";

function Contributors() {
    return (
        <>
            <div className={"page contributors-page"}></div>
            <h1 className="title">Contributors</h1>
            <div className="teamMemberDiv">
                {contributors.map((contributor, i) => {
                    return (
                        <TeamMemberCard
                            key={i}
                            image={contributor.img}
                            name={contributor.name}
                            position={contributor.position}
                            email={contributor.mail}
                            linkedin={contributor.linkedin}
                        />
                    );
                })}
            </div>
        </>
    );
}

export default Contributors;
