function scrollToViewOffset(element, offset) {
    if (!element) return;
    const headerOffset = offset || 90;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
    });
}

export default scrollToViewOffset;
