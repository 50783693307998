import React from "react";

import "./DoubleHeading.css";

function DoubleHeading({ label, ...props }) {
    return (
        <div className={"double-heading"}>
            <span>{label}</span>
            <h2>{label}</h2>
        </div>
    );
}

export default DoubleHeading;
