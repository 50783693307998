import React from "react";
import Section from "../../Section/Section";
import TimelineCard from "../../TimelineCard/TimelineCard";

function EventTimeline(props) {
    const { timelines, eventRefs } = props;

    return (
        <Section
            className={"event-timeline"}
            heading={"Timeline"}
            refId={3}
            eventRefs={eventRefs}
        >
            <div className="timelines">
                {timelines.map((timeline, i) => (
                    <TimelineCard key={i} timelineData={timeline} />
                ))}
            </div>
        </Section>
    );
}

export default EventTimeline;
