import React from 'react';
import "./speaker.css";


function Speaker(props) {
  const im=require("../../assets/speakers/"+props.img);
  
  return (
    <div className="speakerCont">
      <img className="speakerImg" src={im} alt={props.name} />
      <h2>{props.name}</h2>
      <h4>{props.bio}</h4>
      <p className="speakerDesc">{props.description}</p>
    </div>
  );
}

export default Speaker;
