import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NavScrollContext from "../../Contexts/NavScrollContext";
import scrollToViewOffset from "../../util/scrollToViewOffset";

function NavLink({ label, link, refKey }) {
    const navRefs = useContext(NavScrollContext);
    const navigate = useNavigate();

    const onClickHashLink = async (e) => {
        e.preventDefault();
        await navigate(link);
        refKey && scrollToViewOffset(navRefs?.current?.[refKey]?.current);
    };

    return (
        <div className={"navlink"} onClick={onClickHashLink}>
            {label}
        </div>
    );
}

NavLink.defaultProps = {
    link: "/",
};

export default NavLink;
