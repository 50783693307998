import React from "react";
import "./sponsor.css";

export default function Sponsor(props){
    const im = require('../../assets/sponsors/'+props.image);
    return (
            <div className="sponsorCont">
                <h4>{props.title}</h4>
                <div><a href={props.url} target="_blank" rel="noreferrer"> <img src={im} alt={props.name} className="sponsorImg"/></a></div>
            </div>
    );
}
