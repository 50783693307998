import React from 'react'
import './Navbar.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
const Navbar = (props) => {

	const [open, setOpen] = useState(false);
	useEffect(()=>{
		return ()=>{
			setOpen(false);
		}
	},[]);

	return (
		<div className="navCont">
			<header>
				<nav className='navBar'>
					<Link className='logoImage' to={props.home}>
						<img src={props.logo} className='logo' alt="I&E Cell NIT KKR Logo"></img>
					</Link>
					<ul className={`navMenu ${open ? "active" : ""}`}>
						<li onClick={() => {
						setOpen(false)
					}}><Link to="speakers">SPEAKERS</Link> </li>
						<li onClick={() => {
						setOpen(false)
					}}><Link to="competitions">COMPETITIONS</Link> </li>
						<li onClick={() => {
						setOpen(false)
					}}><Link to="startupexpo">STARTUP EXPO</Link> </li>
						<li onClick={() => {
						setOpen(false)
					}}><Link to="schedule">SCHEDULE</Link> </li>
						<li onClick={() => {
						setOpen(false)
					}}><Link to="sponsors">SPONSORS</Link> </li>
					</ul>
					<div className={`hamburger ${open ? "active" : ""}`} onClick={() => {
						setOpen(!open)
					}}>
						
						<span className='bar'></span>
						<span className='bar'></span>
					</div>
				</nav>
			</header>
		</div>
	)
}

export default Navbar;
