import React from "react";
import "./essponsors.css";
import sponsors from "../../data/sponsors.json";
import Sponsor from "../../Components/Sponsors/Sponsor";

const ESSponsors = () => {

  return (
    <>
      <h1 className="ESLayoutSectionHeading">SPONSORS</h1>
      <div className="eventsContainer">
        {
          sponsors.map((sponsor, idx) =>
            <Sponsor key={idx} {...sponsor}  />
          )
        }
      </div>
    </>
  );
}

export default ESSponsors;