import React from "react";

function NavItem(props) {
    const { id, label, selected, onClick } = props;

    const handleClick = () => {
        onClick(id);
    };

    return (
        <p
            className={`sec-nav-item ${selected ? "selected" : ""}`}
            onClick={handleClick}
        >
            {label}
        </p>
    );
}

export default NavItem;
