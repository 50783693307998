import React, { useContext, useRef } from "react";
import DoubleHeading from "../../Components/DoubleHeading/DoubleHeading";
import Carousel from "../../Components/Carousel/Carousel";

import img1 from "../../assets/img/background-1.webp";
import img2 from "../../assets/img/background-2.webp";
import img3 from "../../assets/img/background-3.webp";
import img4 from "../../assets/img/background-4.webp";
import img5 from "../../assets/img/background-5.webp";
import img6 from "../../assets/img/background-6.webp";
import DesignContainer from "../../Components/DesignContainer/DesignContainer";
import AboutCarouselCard from "./AboutCarouselCard";
import NavScrollContext from "../../Contexts/NavScrollContext";

const images = [img1, img2, img3, img4, img5, img6];

const texts = [
    "Startup Cell, NIT Kurukshetra is a non-profit student’s organization dedicated to promoting the spirit of entrepreneurship among college students.",
    "Startup Cell, NIT Kurukshetra is a non-profit student’s organization dedicated to promoting the spirit of entrepreneurship among college students.",
    "Startup Cell, NIT Kurukshetra is a non-profit student’s organization dedicated to promoting the spirit of entrepreneurship among college students.",
    "Startup Cell, NIT Kurukshetra is a non-profit student’s organization dedicated to promoting the spirit of entrepreneurship among college students.",
    "Startup Cell, NIT Kurukshetra is a non-profit student’s organization dedicated to promoting the spirit of entrepreneurship among college students.",
    "Startup Cell, NIT Kurukshetra is a non-profit student’s organization dedicated to promoting the spirit of entrepreneurship among college students.",
];
function AboutUs(props) {
    const navRefs = useContext(NavScrollContext);

    const ref = useRef(null);
    navRefs.current.about = ref;

    return (
        <section id={"home--about"} className={"flex-col"} ref={ref}>
            <DoubleHeading label={"About Us"} />
            <DesignContainer>
                <Carousel count={6} time={5000}>
                    {images.map((image, i) => (
                        <AboutCarouselCard
                            key={i}
                            heading={"Startup Cell, NIT Kurukshetra"}
                            text={texts[i]}
                            image={image}
                        />
                    ))}
                </Carousel>
            </DesignContainer>
        </section>
    );
}

export default AboutUs;
