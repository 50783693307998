import React from "react";
import { AiOutlineMenu } from "react-icons/ai";

function NavMobileMenu({ toggleMenu, ...props }) {
    return (
        <div className={"nav-menu"} onClick={toggleMenu}>
            <AiOutlineMenu />
        </div>
    );
}

NavMobileMenu.defaultProps = {
    mobileMenuHidden: true,
};

export default NavMobileMenu;
