import React from "react";
import InputWithBtn from "../Inputs/InputWithBtn";
import MinimalNavigation from "../Navigation/MinimalNavigation";

function FooterNewsletter(props) {
    return (
        <div className={"footer-newsletter flex-col"}>
            <h4>Our Newsletter</h4>
            <p>Subscribe to our newsletter to get latest updates</p>
            <InputWithBtn placeholder={"E-mail address"} label={"Subscribe"} />
            <MinimalNavigation />
        </div>
    );
}

export default FooterNewsletter;
