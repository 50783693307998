import React, { useState } from "react";
import NavItem from "./NavItem";

import "./SecondaryNavBar.css";

function SecondaryNavBar(props) {
    const { navList, onClick } = props;

    const [selectedIdx, setSelectedIdx] = useState(0);

    const handleClick = (id) => {
        setSelectedIdx(id);
        onClick && onClick(id);
    };

    return (
        <div className={"secondary-navbar"}>
            {navList.map((item, i) => (
                <NavItem
                    key={i}
                    id={i}
                    onClick={handleClick}
                    label={item.label}
                    selected={selectedIdx === i}
                />
            ))}
        </div>
    );
}

export default SecondaryNavBar;
