import React, { useEffect } from "react";
import "./Navigation.css";
import NavIcon from "./NavIcon";
import NavLinksContainer from "./NavLinksContainer";
import NavMobileMenu from "./NavMobileMenu";
import useResponsiveNavigation from "./hooks/useResponsiveNavigation";
import NavigationTitle from "./NavigationTitle";
import { useLocation, useNavigate } from "react-router-dom";

const Navigation = () => {
    const [mobileMenuHidden, toggleMenu] = useResponsiveNavigation();

    const location = useLocation();
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate("/");
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        location.hash === "" && window.scrollTo(0, 0);
    }, [location.pathname, location.hash]);

    return (
        <nav className="navigation">
            <div className={"flex-row"}>
                <NavIcon navigateToHome={navigateToHome} />
                <NavigationTitle onClick={navigateToHome} />
                {!mobileMenuHidden && (
                    <div className={"nav-wrapper"} onClick={toggleMenu} />
                )}
                <NavMobileMenu toggleMenu={toggleMenu} />
            </div>
            <NavLinksContainer mobileMenuHidden={mobileMenuHidden} />
        </nav>
    );
};

export default Navigation;
