import React from "react";

function CarouselItem({ active, passedRef, ...props }) {
    return (
        <div
            className={
                "carousel-item" +
                (active ? " carousel-active" : " carousel-hidden")
            }
            ref={passedRef}
            // style={{ flex: "0 0 " + width + "px" }}
        >
            {props.children}
        </div>
    );
}

export default CarouselItem;
