import AmongUSContent from "./BlogsData/AmongUS/Content";
import UnicornContent from "./BlogsData/Unicorn/Content";
import BlackberryContent from "./BlogsData/BlackBerry/Content";
import CryptoGuideContent from "./BlogsData/CryptoGuide/Content";
import RussiaUkraineContent from "./BlogsData/russia-ukraine/Content";
import unicornBanner from "../../assets/img/blogs/unicorn/banner.png";
import amongusBanner from "../../assets/img/blogs/amongus/banner.jpg";
import blackberryBanner from "../../assets/img/blogs/blackberry/banner.jpg";
import cryptoguidBanner from "../../assets/img/blogs/cryptoguide/banner.jpg";
import warBanner from "../../assets/img/blogs/blogimages/banner.png";

const blogs = [
    {
        route: "amongus",
        shortHeading: "The case study on Among US",
        heading: "The case study on Among US",
        authorDetails: {
            name: "Vibhuti Jindal",
            avatar: "https://www.w3schools.com/howto/img_avatar.png",
            desc: "",
        },
        desc: "Taking inspiration from a live game “Mafia” and science fiction horror film “The Thing”, Indie developers InnerSloth launched their online multiplayer murder mystery mobile game “Among Us” that runs on Unity Engine. It was first released on June 15, 2018 for iOS and Android and was later released on Steam in August 2018",
        likes: 5,
        bannerImg: amongusBanner,
        tags: ["Among US"],
        content: <AmongUSContent />,
    },
    {
        route: "unicorn",
        shortHeading: "The Unicorn Club: A dream, An aspiration",
        heading: "The Unicorn Club: A dream, An aspiration, The Winner of 2021",
        authorDetails: {
            name: "Aman Goel",
            avatar: "https://www.w3schools.com/howto/img_avatar.png",
            desc: "",
        },
        desc: "The trends from 2021 were clearly sided towards the global emergence of India’ startup ecosystem. With a total of 43 startups entering the, now popular, Unicorn Club , i.e. organisations with a valuation of more than $1 billion",
        likes: 5,
        bannerImg: unicornBanner,
        tags: ["Unicorn"],
        content: <UnicornContent />,
    },
    {
        route: "blackberry",
        shortHeading: "The fall of BlackBerry : The end of a Legacy",
        heading: "The fall of BlackBerry : The end of a Legacy",
        authorDetails: {
            name: "Lakshay Bhati",
            avatar: "https://www.w3schools.com/howto/img_avatar.png",
            desc: "",
        },
        desc: "Blackberry launched its first handheld device in the year 1999 in the form of a two-way pager. Who would have thought back then that the company with an innovative product like this will soon see it’s fall?",
        likes: 5,
        bannerImg: blackberryBanner,
        tags: ["Blackberry"],
        content: <BlackberryContent />,
    },
    {
        route: "cryptoguide",
        shortHeading: "Crypto Guide: A guide to the world of Cryptocurrencies",
        heading: "Crypto Guide: A guide to the world of Cryptocurrencies",
        authorDetails: {
            name: "Praneet Brar",
            avatar: "https://www.w3schools.com/howto/img_avatar.png",
            desc: "",
        },
        desc: "Start your online business has been the most used acronym since thestart of the lockdown. Did you come across it?",
        likes: 5,
        bannerImg: cryptoguidBanner,
        tags: ["Crypto"],
        content: <CryptoGuideContent />,
    },
    {
        route: "russiaukraine",
        shortHeading: "Effects on Crude oil due to war",
        heading: "Effects on Crude oil due to war",
        authorDetails: {
            name: "Koustubh Chouksey",
            avatar: "https://www.w3schools.com/howto/img_avatar.png",
            desc: "",
        },
        desc: "How crude oil showing volatility due to war?",
        likes: 5,
        bannerImg: warBanner,
        tags: ["Crude Oil", "Russia", "Ukraine", "India"],
        content: <RussiaUkraineContent />,
    },
];

export default blogs;
