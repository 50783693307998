import React, { useEffect, useState } from "react";

import "./Page404.css";

import page404img from "../../assets/img/page404img.svg";
import { useNavigate } from "react-router-dom";

function Page404(props) {

    const [timer, setTimer] = useState(5);

    const navigate = useNavigate();

    useEffect(() => {

        setTimer(5);
        const interval = setInterval(() => {
            setTimer(prevTimer => {
                if(prevTimer-1 <= 0) {
                    clearInterval(interval);
                    setTimeout(() => navigate("/"), 1000);
                }
                return prevTimer-1;
            });
        }, 1000)
        return () => clearInterval(interval);
    }, [navigate])


    return (
        <section id={"page-404"}>
            <img className={"img-404"} src={page404img} alt="404" />

            <div className={"page-404-text"}>
                <p>The page you were looking for doesn't exist.</p>
                <p>Redirecting you to homepage in <span>{timer} seconds...</span></p>
            </div>
        </section>
    );
}

export default Page404;

