import React from "react";
import "./Home.css";
import Welcome from "./Welcome";
import AboutUs from "./AboutUs";
import Events from "./Events";
import Contacts from "./Contacts";
import Team from "./Team";

const Home = () => {
    return (
        <div id={"home"}>
            <Welcome />
            <AboutUs />
            <Events />
            <Team />
            <Contacts />
        </div>
    );
};

export default Home;
