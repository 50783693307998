import React from "react";

import "./Aos.css";
import AosApplier from "./AosApplier";

function AosFade({ children, duration, onView }) {
    return (
        <AosApplier type={"fade"} duration={duration} onView={onView}>
            {children}
        </AosApplier>
    );
}

export default AosFade;
