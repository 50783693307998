import React, { useContext, useRef } from "react";
import Carousel from "../../Components/Carousel/Carousel";
import TeamCard from "../../Components/TeamCard/TeamCard";
import TeamCardsContainer from "../../Components/TeamCard/TeamCardsContainer";
import NavScrollContext from "../../Contexts/NavScrollContext";
import Button from "../../Components/Button/Button";

import teams from "./../../Data/teams.json";

function Team(props) {
    const navRefs = useContext(NavScrollContext);

    const ref = useRef(null);
    navRefs.current.team = ref;

    const time = 10000;

    return (
        <section id={"home--team"} className={"flex-col"} ref={ref}>
            <h2>Team</h2>
            <Carousel count={Math.ceil(teams.length / 3)} time={time}>
                {[...Array(Math.ceil(teams.length / 3))].map((_, i) => (
                    <TeamCardsContainer key={i}>
                        {[...Array(3)].map(
                            (m_, mi) =>
                                i * 3 + mi <= teams.length - 1 && (
                                    <TeamCard
                                        key={i * 3 + mi}
                                        {...teams[i * 3 + mi]}
                                    />
                                )
                        )}
                    </TeamCardsContainer>
                ))}
            </Carousel>
            <Carousel count={teams.length} className={"teams-carousel-mobile"} time={time}>
                {teams.map((member, i) => (
                    <TeamCardsContainer key={i}>
                        <TeamCard {...member} />
                    </TeamCardsContainer>
                ))}
            </Carousel>
            <Button label={"View More"} link={"/teams"} />
        </section>
    );
}

export default Team;
