import React, { useEffect, useState } from "react";
import CarouselDots from "./CarouselDots";
import CarouselControls from "./CarouselControls";
import useCarouselControl from "./hooks/useCarouselControl";
import Slider from "./Slider";

function Carousel({ count, className, forceActive, onChange, time, ...props }) {
    const [active, setActive, prev, next] = useCarouselControl(
        count,
        forceActive,
        onChange,
        time
    );
    const [timer, setTimer] = useState(undefined);

    function Timer(fn, t) {
        var timerObj = setInterval(fn, t);

        this.stop = function () {
            if (timerObj) {
                clearInterval(timerObj);
                timerObj = null;
            }
            return this;
        };

        this.start = function () {
            if (!timerObj) {
                this.stop();
                timerObj = setInterval(fn, t);
            }
            return this;
        };

        this.reset = function (newT = t) {
            t = newT;
            return this.stop().start();
        };
    }

    useEffect(() => {
        if (forceActive === undefined) {
            setTimer(
                new Timer(function () {
                    setActive((prevActive) =>
                        prevActive === count - 1 ? 0 : prevActive + 1
                    );
                }, time)
            );
        }
    }, [setActive, forceActive, count]);

    return (
        <div className={"carousel" + (className === "" ? "" : " " + className)}>
            <CarouselControls prev={prev} next={next} timer={timer}>
                <Slider
                    active={forceActive !== undefined ? forceActive : active}
                    setActive={setActive}
                >
                    {props.children}
                </Slider>
            </CarouselControls>
            <CarouselDots
                count={count}
                active={forceActive !== undefined ? forceActive : active}
            />
        </div>
    );
}

Carousel.defaultProps = {
    className: "",
};

export default Carousel;
