import React from "react";
import Tag from "../../Tag/Tag";

function Tags(props) {
    const { tags } = props;

    return (
        <div className={"tags"}>
            {tags.map((tag, i) => (
                <Tag key={i} name={tag} />
            ))}
        </div>
    );
}

export default Tags;
