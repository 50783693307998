import React from "react";

import "./Carousel.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

function CarouselControls({ prev, next, timer, ...props }) {
    return (
        <div className={"carousel-controls flex-row"}>
            <div className={"carousel-control control-prev"} onClick={()=>{prev(timer)}}>
                <FaArrowLeft />
            </div>
            {props.children}
            <div className={"carousel-control control-next"} onClick={()=>{next(timer)}}>
                <FaArrowRight />
            </div>
        </div>
    );
}

export default CarouselControls;
