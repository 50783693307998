import React from "react";
import Button from "../Button/Button";

import "./ButtonedImage.css";

function ButtonedImage(props) {
    const { image, label, link } = props;

    return (
        <div className={"buttoned-image"}>
            <img src={image} alt="buttoned-img" />
            <Button label={label} onClick={() => window.open(link, "_blank")}  />
        </div>
    );
}

export default ButtonedImage;
