import React from "react";
import Tags from "./Tags";
import AuthorDetails from "../AuthorDetails";

function RightSection(props) {
    const { banner, heading, tags, content, authorDetails } = props;

    return (
        <div className="right-section">
            <img className={"banner-img"} src={banner} alt="blog-banner-img" />
            <h1>{heading}</h1>
            <AuthorDetails {...authorDetails} />
            <Tags tags={tags} />
            <div className="blog-content">{content}</div>
        </div>
    );
}

export default RightSection;
