import React from "react";

import "./LabeledAvatar.css";

function LabeledAvatar(props) {
    const { avatar, label } = props;

    return (
        <div className={"labeled-avatar"}>
            <img src={avatar} alt="avatar-img" />
            {label && <p>{label}</p>}
        </div>
    );
}

export default LabeledAvatar;
