import React from "react";
import SocialIcon from "../SocialIcon/SocialIcon";
import {
    FaDiscord,
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaTelegramPlane,
} from "react-icons/fa";
import SocialIconsContainer from "../SocialIcon/SocialIconsContainer";

function FooterInfo(props) {
    return (
        <div className={"footer-info"}>
            <h1>Startup Cell</h1>
            <p>
                National Institute of Technology <br />
                Kurukshetra, India
            </p>
            <SocialIconsContainer className={"footer-socialicons"}>
                <SocialIcon
                    icon={<FaInstagram />}
                    link={"https://www.instagram.com/icell.nitkkr/?hl=en"}
                />
                <SocialIcon
                    icon={<FaLinkedinIn />}
                    link={
                        "https://www.linkedin.com/company/industry-cell-nit-kurukshetra/?originalSubdomain=in"
                    }
                />
                <SocialIcon
                    icon={<FaFacebookF />}
                    link={"https://www.facebook.com/industrycell/"}
                />
                <SocialIcon
                    icon={<FaDiscord />}
                    link={"https://discord.gg/MZA2hYqpFC"}
                />
                <SocialIcon
                    icon={<FaTelegramPlane />}
                    link={"https://t.me/joinchat/7XrdpFRXIX84ZDM9"}
                />
            </SocialIconsContainer>
        </div>
    );
}

export default FooterInfo;
