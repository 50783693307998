import React, { useEffect } from "react";

import "./Events.css";
import EventDetails from "../../Components/Event/EventDetails/EventDetails";
import events from "./events.json";
import { useNavigate, useParams } from "react-router-dom";

function Events(props) {
    const { id } = useParams();
    const navigate = useNavigate();

    const eventData = events.find((e) => e.route === id);

    useEffect(() => {
        if (eventData === undefined) {
            navigate("/error404");
        }
    }, [eventData, navigate]);

    return (
        <div className={"page events-page"}>
            {eventData && <EventDetails eventData={eventData} />}
        </div>
    );
}

export default Events;
