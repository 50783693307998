import { useState } from "react";

function useCarouselControl(count, forceActive, onChange, time) {
    const [active, setActive] = useState(
        forceActive !== undefined ? forceActive : 0
    );

    const prev = (timer) => {
        timer?.reset(time);
        if (forceActive !== undefined)
            onChange &&
                onChange(forceActive === 0 ? count - 1 : forceActive - 1);
        else {
            setActive((prevActive) => {
                const changeTo = prevActive === 0 ? count - 1 : prevActive - 1;
                onChange && onChange(changeTo);
                return changeTo;
            });
        }
    };

    const next = (timer) => {
        timer?.reset(4000);
        if (forceActive !== undefined)
            onChange &&
                onChange(forceActive === count - 1 ? 0 : forceActive + 1);
        else {
            setActive((prevActive) => {
                const changeTo = prevActive === count - 1 ? 0 : prevActive + 1;
                onChange && onChange(changeTo);
                return changeTo;
            });
        }
    };

    return [active, setActive, prev, next];
}

export default useCarouselControl;
