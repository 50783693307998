import React, { useState } from "react";
import "./EventCard.css";

export default function EventCard(props) {
    const [isOpen, setOpen] = useState(false);
    const ic = require(`../../assets/${props.icon}`);

    return (
        <div className="outer">
            <div onClick={() => setOpen(!isOpen)} className="normal-card">
                <div className="cardIconCont">
                    <img src={ic} alt="icon" className="cardIcon" />
                </div>
                <h3 className="cardTitle">{props.title}</h3>
                {
                    isOpen ? (<div className="card-add-ons">
                        <p>{props.desc}</p>
                        {props?.regurl ? (
                            <button className="esbutn">
                                <a href={props.regurl}>REGISTER</a>
                            </button>
                        ) : null}
                    </div>)
                        : null
                }
            </div>
        </div>
    );
}
