import React from "react";

import "./LabeledImage.css";

function LabeledImage(props) {
    const { className, image, label } = props;

    return (
        <div className={`image-container${className ? " " + className : ""}`}>
            <img src={image} alt="labeled-img" />
            <p>{label}</p>
        </div>
    );
}

export default LabeledImage;
