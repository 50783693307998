import { useEffect, useState } from "react";

function useResponsiveNavigation() {
    const [mobileMenuHidden, setMobileMenuHidden] = useState(
        window.innerWidth <= 830
    );

    const toggleMenu = () => {
        setMobileMenuHidden((prevState) => !prevState);
    };

    useEffect(() => {
        const responsiveHandler = () => {
            setMobileMenuHidden(window.innerWidth <= 830);
        };

        window.addEventListener("resize", responsiveHandler);
        return () => window.removeEventListener("resize", responsiveHandler);
    }, []);

    return [mobileMenuHidden, toggleMenu];
}

export default useResponsiveNavigation;
