import React, {useState} from "react";
import "./TeamsPage.css";
import TeamName from "../../Components/Teams/TeamName/TeamName";
import DesignContainer from "../../Components/DesignContainer/DesignContainer";
import Carousel from "../../Components/Carousel/Carousel";
import AboutCarouselCard from "../Home/AboutCarouselCard";
import MarketingPic from "../../assets/img/team/carousel/mediamarketing.webp";
import TechPic from "../../assets/img/team/carousel/techteam.webp";
import CorporatePic from "../../assets/img/team/carousel/corporaterelations.webp";
import TeamMember from "../../Components/Teams/TeamMember/TeamMember";

const carousel = [
    {
        img: MarketingPic,
        heading: "Media & Marketing Team",
        text: "Media team handles the reach of Startup cell on various social media platforms. It remains active throughout the year and keeps posting insightful content on social media handles of Startup cell. It is responsible for creating digital content and posters for events.",
    },
    {
        img: TechPic,
        heading: "Technical Team",
        text: "Tech team is responsible for managing the technical chores. They continously work on and keep updating the stock hunt app and official website of Startup Cell.",
    },
    {
        img: CorporatePic,
        heading: "Corporate Relations Team",
        text: "Corporate team is responsible for networking and outreach. It brings sponsers and external participation in events. It also collaborates with E-cell/I-cell of other colleges to conduct events.",
    },
];

function TeamsPage() {

    const [active, setActive] = useState(0);
 
    const handleChange = (currActive) => {
        setActive(currActive);
    }

    const changeTeam = (id) => {
        setActive(id);
    }

    return (
        <>
            <div className={"page teams-page"}>
                <TeamName changeTeam={changeTeam}/>
                <section className={"team-carousel flex-row"} id={"teamPage-carousel"}>
                    <DesignContainer>
                        <Carousel count={3} onChange={handleChange} forceActive={active}>
                            {carousel.map((detail, i) => (
                                <AboutCarouselCard
                                    key={i}
                                    heading={detail.heading}
                                    text={detail.text}
                                    image={detail.img}
                                />
                            ))}
                        </Carousel>
                    </DesignContainer>
                </section>
                <TeamMember active={active} />
            </div>
        </>
    );
}

export default TeamsPage;
