import React from "react";
import "./schedule.css";
import ScheduleCard from "../../Components/ScheduleCard/ScheduleCard";
import schedule from "../../data/schedule.json";

const Schedule = () => {


  return (
    <>
      <h1 className="ESLayoutSectionHeading">SCHEDULE</h1>
      <div className="eventsContainer">
        {
          schedule.map((day, idx) =>
            <div key={idx}>
              <div className="scheduleDate">{day.date}</div>
              <div className="scheduleEvents">{
                day.events.map((val, idx1) => <ScheduleCard key={idx1} eventName={val.name} eventType={val.type} eventTime={val.time} eventVenue={val.venue} />)
              }</div>
            </div>
          )
        }
      </div>
    </>
  );
}

export default Schedule;