import React, { useState } from "react";
import LabeledAvatar from "../LabeledAvatar/LabeledAvatar";

function AuthorDetails(props) {
    const { name, avatar, desc } = props;

    const [collapsed, setCollapsed] = useState(false);

    const handleClick = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={"author-details"} onClick={handleClick}>
            <LabeledAvatar avatar={avatar} label={name} />
            <p className={`author-desc${collapsed ? " collapsed" : ""}`}>
                {desc}
            </p>
        </div>
    );
}

export default AuthorDetails;
