import React from "react";
import HeadingWParagraph from "../HeadingWParagraph/HeadingWParagraph";

import "./IconCard.css";

function IconCard(props) {
    const { icon, title, subTitle } = props;

    return (
        <div className={"icon-card"}>
            {React.createElement(icon)}
            <HeadingWParagraph heading={title} paragraph={subTitle} />
        </div>
    );
}

export default IconCard;
