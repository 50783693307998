import React from "react";

import "./HeadingWParagraph.css";

function HeadingWParagraph({ heading, paragraph, className, ...props }) {
    return (
        <div
            className={
                "heading-para" + (className === "" ? "" : " " + className)
            }
        >
            <h2>{heading}</h2>
            <p>{paragraph}</p>
        </div>
    );
}

HeadingWParagraph.defaultProps = {
    className: "",
};
export default HeadingWParagraph;
