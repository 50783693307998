import React, { useEffect, useState } from "react";

function useResponsiveCarousel(active, setActive, children) {
    const [refs, setRefs] = useState([]);

    useEffect(() => {
        const count = React.Children.count(children);

        setRefs((elRefs) =>
            [...Array(count)].map((_, i) => elRefs[i] || React.createRef())
        );
    }, [children]);

    const [currentSlideWidth, setCurrentSlideWidth] = useState(
        (refs[active]?.current
            ? refs[active].current.getBoundingClientRect().width
            : 0) *
            active *
            -1
    );

    useEffect(() => {
        setCurrentSlideWidth(
            (refs[active]?.current
                ? refs[active].current.getBoundingClientRect().width
                : 0) *
                active *
                -1
        );
    }, [active, refs]);

    useEffect(() => {
        const handleResponsive = () => {
            setActive(0);
        };

        window.addEventListener("resize", handleResponsive);
        return () => window.removeEventListener("resize", handleResponsive);
    }, [setActive]);

    return [currentSlideWidth, refs];
}

export default useResponsiveCarousel;
