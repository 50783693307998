import React from 'react'
import Event from '../../Components/Event/Event';
import competitions from "../../data/competitions.json";

export default function ESCompetitions() {
    return (
        <>
            <Event eventType="Competitions" eventData={competitions}/>
        </>
    );
}
