import React from "react";
import Section from "../../Section/Section";

function EventAbout(props) {
    const { about, eventRefs } = props;

    return (
        <Section
            className={"event-about"}
            heading={"About"}
            refId={0}
            eventRefs={eventRefs}
        >
            <p>{about}</p>
        </Section>
    );
}

export default EventAbout;
