import React from "react";
import "./ProgressPage.css";
import facts from "../../data/facts.json";
import { TbGift } from "react-icons/tb";

function ProgressPage() {
    const randomIndex=Math.floor(Math.random() * facts.length);
    return (
        <>
            <div className="progressCont">
                <TbGift className="progressIcon" />
                <h1>Revealing soon!</h1>
                <p>Quick fact: {facts[randomIndex]}</p>
            </div>
        </>
    );
}

export default ProgressPage;
