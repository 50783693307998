import React from "react";

function CarouselDots({ count, active, ...props }) {
    return (
        <div className={"carousel-dots flex-row"}>
            {[...Array(count)].map((e, i) => (
                <div
                    key={i}
                    className={"carousel-dot" + (active === i ? " active" : "")}
                />
            ))}
        </div>
    );
}

export default CarouselDots;
