import { useEffect, useRef, useState } from "react";

function useAosScrollIntoView() {
    const [inView, setInView] = useState(false);

    const refEl = useRef(null);

    const isInView = () => {
        if (!refEl?.current) return false;
        const elTop = refEl.current.getBoundingClientRect().top;
        return elTop >= 0 && elTop <= window.innerHeight;
    };

    useEffect(() => {
        const handleScroll = () => {
            if (isInView()) setInView(true);
            else setInView(false);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return [refEl, inView];
}

export default useAosScrollIntoView;
