import React from "react";

function SocialIconsContainer({ className, ...props }) {
    return (
        <div
            className={
                "flex-row social-icons-container" +
                (className === "" ? "" : " " + className)
            }
        >
            {props.children}
        </div>
    );
}

SocialIconsContainer.defaultProps = {
    className: "",
};

export default SocialIconsContainer;
