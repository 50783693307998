import React from 'react'
import "./Event.css"
import EventCard from "../EventCard/EventCard";

export default function Event(props) {
    return (
        <>
            <h1 className="ESLayoutSectionHeading">{props.eventType}</h1>
            <div className='cards'>
                {
                    props.eventData.map((val, i) =>
                        <EventCard key={i} title={val.title} icon={val.icon} desc={val.desc} regurl={val.regurl} />
                    )
                }
            </div>
        </>
    );
}
