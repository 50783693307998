import React, { useContext, useRef } from "react";
import DoubleHeading from "../../Components/DoubleHeading/DoubleHeading";
import { GoLocation } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { MdPhoneInTalk } from "react-icons/md";
import IconCard from "../../Components/IconCard/IconCard";
import GoogleMap from "./GoogleMap";
import NavScrollContext from "../../Contexts/NavScrollContext";

const contacts = [
    {
        icon: GoLocation,
        title: "Our Address",
        subTitle: "Startup Cell Room, AB Block, NIT Kurukshetra",
        href: "https://goo.gl/maps/ksiYu4R899ucdji8A",
        target: "_blank",
    },
    {
        icon: HiOutlineMail,
        title: "Email Us",
        subTitle: "icell@nitkkr.ac.in",
        href: "mailto:icell@nitkkr.ac.in",
    },
    {
        icon: MdPhoneInTalk,
        title: "Call Us",
        subTitle: "+91 77040 42001",
        href: "tel:+917704042001",
    },
];

function Contacts(props) {
    const navRefs = useContext(NavScrollContext);

    const ref = useRef(null);
    navRefs.current.contacts = ref;

    return (
        <section id={"home--contacts"} className={"flex-col"} ref={ref}>
            <DoubleHeading label={"Contacts"} />
            <div className="contact-cards">
                {contacts.map((contact, i) => (
                    <a key={i} href={contact.href} target={contact.target}>
                        <IconCard {...contact} />
                    </a>
                ))}
            </div>
            <GoogleMap />
        </section>
    );
}

export default Contacts;
