import React, { useEffect } from "react";
import BlogPage from "../../Components/Blog/BlogPage/BlogPage";

import "./Blogs.css";
import { useNavigate, useParams } from "react-router-dom";
import blogs from "../Blogs/blogs.js";

function Blogs(props) {
    const { id } = useParams();

    const navigate = useNavigate();

    const blogData = blogs.find((e) => e.route === id);

    useEffect(() => {
        if (blogData === undefined) {
            navigate("/error404");
        }
    }, [blogData, navigate]);

    return (
        <div className={"page blogs-page"}>
            {blogData && <BlogPage blog={blogData} />}
        </div>
    );
}

export default Blogs;
