import React from "react";
import { Link } from "react-router-dom";

export default function BlogFeatCard(props) {
    const style = {};
    if (props.image) style.backgroundImage = `url(${props.image})`;

    return (
        <Link
            className="blog-list-feat-card animateBlogElements"
            to={"#"}
            style={style}
        >
            <h3>{props.Category}</h3>
            <h1>{props.Description}</h1>
        </Link>
    );
}
